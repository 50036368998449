/* https://codepen.io/dimsemenov/pen/GAIkt */
.mfp-removing .mfp-arrow {
  opacity: 0;
}
/*

====== Zoom effect ======

*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
/*

====== Newspaper effect ======

*/
.mfp-newspaper {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-newspaper .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.5s;
  transform: scale(0) rotate(500deg);
}
.mfp-newspaper.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}
.mfp-newspaper.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}
.mfp-newspaper.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-newspaper.mfp-removing .mfp-with-anim {
  transform: scale(0) rotate(500deg);
  opacity: 0;
}
.mfp-newspaper.mfp-removing.mfp-bg {
  opacity: 0;
}
/*

====== Move-horizontal effect ======

*/
.mfp-move-horizontal {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-move-horizontal .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-50px);
}
.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  transition: all 0.3s;
}
.mfp-move-horizontal.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateX(0);
}
.mfp-move-horizontal.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-move-horizontal.mfp-removing .mfp-with-anim {
  transform: translateX(50px);
  opacity: 0;
}
.mfp-move-horizontal.mfp-removing.mfp-bg {
  opacity: 0;
}
/*

====== Move-from-top effect ======

*/
.mfp-move-from-top {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-move-from-top .mfp-content {
  vertical-align: top;
}
.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s;
  transform: translateY(-100px);
}
.mfp-move-from-top.mfp-bg {
  opacity: 0;
  transition: all 0.2s;
}
.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateY(0);
}
.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-move-from-top.mfp-removing .mfp-with-anim {
  transform: translateY(-50px);
  opacity: 0;
}
.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0;
}
/*

====== 3d unfold ======

*/
.mfp-3d-unfold {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-3d-unfold .mfp-content {
  perspective: 2000px;
}
.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
  transform: rotateY(-60deg);
}
.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}
.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: rotateY(0deg);
}
.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  transform: rotateY(60deg);
  opacity: 0;
}
.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0;
}
/*

====== Zoom-out effect ======

*/
.mfp-zoom-out {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
}
.mfp-zoom-out.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-out.mfp-removing .mfp-with-anim {
  transform: scale(1.3);
  opacity: 0;
}
.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0;
}
/*

====== "Hinge" close effect ======

*/
@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}
.hinge {
  animation-duration: 1s;
  animation-name: hinge;
}
.mfp-with-fade .mfp-content,
.mfp-with-fade.mfp-bg {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.mfp-with-fade.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-with-fade.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-with-fade.mfp-removing.mfp-bg {
  opacity: 0;
}
/***** GALLERY SLIDER EFFECTS ***************/
.mfp-ready .mfp-figure {
  opacity: 0;
}
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-figure,
.mfp-zoom-in .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 0;
  transition: all 0.3s ease-out;
  transform: scale(0.95);
}
.mfp-zoom-in.mfp-bg,
.mfp-zoom-in .mfp-preloader {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-image-loaded .mfp-figure,
.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg,
.mfp-zoom-in.mfp-ready .mfp-preloader {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-figure,
.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 0;
  transform: scale(0.95);
}
.mfp-zoom-in.mfp-removing.mfp-bg,
.mfp-zoom-in.mfp-removing .mfp-preloader {
  opacity: 0;
}
.mfp-iframe-scaler {
  overflow: visible;
  /*so the close button is shown*/
}
.mfp-zoom-out-cur {
  cursor: auto;
}
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer;
}
/***************** ELEMENTS ********************/
.mfp-gallery button.mfp-close {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -13px;
  text-align: center;
  border-radius: 100px;
  border: 2px solid transparent;
  line-height: 38px;
  padding: 0;
  top: 25px;
  transition: all 0.3s ease-out;
  font-family: Arial, monospace !important;
}
.mfp-gallery button.mfp-close:hover {
  border: 2px solid #fff;
  transform: scale(0.8);
}
/*# sourceMappingURL=animation.css.map */
